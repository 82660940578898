

import { Typography } from "@mui/joy";
import classes from "./LoaderPage.module.css";


export default function LoaderPage() {
    return (
        <div className={classes.loader_page}>
            <div className={classes.loader_block}>
                <div className={classes.loader}></div>
            </div>
        </div>
    );
}