import { Button, DialogContent, DialogTitle, FormControl, FormLabel, Input, Modal, ModalClose, ModalDialog, ModalOverflow, Stack, Typography } from "@mui/joy";
import { useContext, useState } from "react";
import { LayoutContext } from "../../context";
import { motion, AnimatePresence } from "framer-motion";
import InputMask from 'react-input-mask';
import { useLocation, useNavigate } from "react-router-dom";


export default function ModalRegistration() {
    const navigate = useNavigate();
    const { modalReg, setModalReg } = useContext(LayoutContext);

    const [ phone, setPhone ] = useState();

    return (
        <AnimatePresence>
            {
                modalReg && (
                    <Modal
                        open={modalReg}
                        onClose={() => setModalReg(false)}
                        slotProps={{
                            backdrop: {
                                component: motion.div,
                                initial: { opacity: 0, backdropFilter: 'blur(0px)' },
                                animate: { opacity: 1, backdropFilter: 'blur(8px)' },
                                exit: { opacity: 0, backdropFilter: 'blur(0px)' },
                                transition: { duration: 0.3, ease: "easeInOut" },
                            }
                        }}
                    >
                        <ModalOverflow sx={{ height: 'auto' }}>
                            <ModalDialog
                                size="lg"
                                component={motion.div}
                                initial={{ opacity: 0, y: -10, scale: 0.98 }}
                                animate={{ opacity: 1, y: 0, scale: 1 }}
                                exit={{ opacity: 0, y: 10, scale: 0.98 }}
                                transition={{ duration: 0.2, ease: "easeInOut" }}
                            >
                                <ModalClose />
                                <Typography sx={{ textAlign: 'center', mb: 3 }} level="h3">
                                    Регистрация
                                </Typography>
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        setModalReg(false);
                                        navigate("/lk/unit");
                                    }}
                                >
                                    <Stack spacing={2}>
                                        <FormControl>
                                            <FormLabel>Имя*</FormLabel>
                                            <Input size="sm" autoFocus required name="name" placeholder="Иван" />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Номер телефона*</FormLabel>
                                            <InputMask mask="+7 (999) 999-99-99" value={phone} onChange={(e) => setPhone(e.target.value)}>
                                                {(inputProps) => <Input {...inputProps} type="tel" size="sm" required name="phone" disableUnderline placeholder="+7 (XXX) XXX-XX-XX" />}
                                            </InputMask>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Почта*</FormLabel>
                                            <Input size="sm" required type="email" name="email" placeholder="admin@gmail.com" />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Telegram</FormLabel>
                                            <Input size="sm" required type="url" name="telegram" placeholder="https://t.me/test" />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Пароль*</FormLabel>
                                            <Input size="sm" required type="password" name="pwd" placeholder="***" />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Повторить пароль*</FormLabel>
                                            <Input size="sm" required type="password" name="pwd_repeat" placeholder="***" />
                                        </FormControl>
                                        <Button variant="solid" color="success" type="submit">
                                            Зарегистрироваться
                                        </Button>
                                    </Stack>
                                </form>
                            </ModalDialog>
                        </ModalOverflow>
                    </Modal>
                )
            }
        </AnimatePresence>
    );
}