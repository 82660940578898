import { createContext, useEffect, useState } from "react";
import { APIGetUser } from "./api";



export const LayoutContext = createContext();


export default function LayoutProvider({ children }) {
    const [ sidebarOpen, setSidebarOpen ] = useState(false);

    const [ account, setAccount ] = useState({});

    const [ modalRate, setModalRate ] = useState(false);
    const [ modalReg, setModalReg ] = useState(false);
    const [ modalAuth, setModalAuth ] = useState(false);
    const [ modalLogout, setModalLogout ] = useState(false);
    const [ modalInfo, setModalInfo ] = useState({ info: undefined, open: false });

    // Формат вывода денег
    function formatPrice(price) {
        return new Intl.NumberFormat('ru-RU').format(price);
    }


    useEffect(() => {
        APIGetUser().then((data) => {
            if (data) {
                console.log("Пользователь авторизован", data);
                setAccount(data);
                return
            }
            console.log("Пользователь не авторизован", data);
        })
    }, []);


    const [ lock, setLock ] = useState(true);
    
    const values = {
        sidebarOpen, setSidebarOpen,
        formatPrice,
        account, setAccount,

        modalRate, setModalRate,
        modalReg, setModalReg,
        modalAuth, setModalAuth,
        modalLogout, setModalLogout,
        modalInfo, setModalInfo,

        lock, setLock
    }
    
    return (
        <LayoutContext.Provider value={values}>
            {children}
        </LayoutContext.Provider>
    );
}