import { Button, DialogContent, DialogTitle, FormControl, FormLabel, Input, Modal, ModalClose, ModalDialog, ModalOverflow, Stack, Typography } from "@mui/joy";
import { useContext, useState } from "react";
import { LayoutContext } from "../../context";
import { motion, AnimatePresence } from "framer-motion";
import InputMask from 'react-input-mask';
import { useLocation, useNavigate } from "react-router-dom";


import { APIAuthUser } from "../../api";
import Cookies from 'js-cookie'



export default function ModalLogout() {
    const navigate = useNavigate();
    const { modalLogout, setModalLogout, setAccount } = useContext(LayoutContext);


    async function Logout(e) {
        setModalLogout(false);
        setAccount({});
        Cookies.remove('authToken');
        navigate("/");
    }


    return (
        <AnimatePresence>
            {
                modalLogout && (
                    <Modal
                        open={modalLogout}
                        onClose={() => setModalLogout(false)}
                        slotProps={{
                            backdrop: {
                                component: motion.div,
                                initial: { opacity: 0, backdropFilter: 'blur(0px)' },
                                animate: { opacity: 1, backdropFilter: 'blur(8px)' },
                                exit: { opacity: 0, backdropFilter: 'blur(0px)' },
                                transition: { duration: 0.3, ease: "easeInOut" },
                            }
                        }}
                    >
                        <ModalOverflow sx={{ height: 'auto' }}>
                            <ModalDialog
                                size="lg"
                                component={motion.div}
                                initial={{ opacity: 0, y: -10, scale: 0.98 }}
                                animate={{ opacity: 1, y: 0, scale: 1 }}
                                exit={{ opacity: 0, y: 10, scale: 0.98 }}
                                transition={{ duration: 0.2, ease: "easeInOut" }}
                            >
                                <ModalClose/>
                                <Typography sx={{ textAlign: 'center', mb: 0 }} level="h3">
                                    Выход
                                </Typography>
                                <Typography textColor="text.tertiary" sx={{ mb: 1 }}>
                                    Вы уверены что хотите выйти из аккаунта?
                                </Typography>
                                <Stack spacing={1} direction={'row'}>
                                    <Button variant="solid" color="danger" onClick={Logout}>
                                        Выйти
                                    </Button>
                                    <Button variant="solid" color="neutral" onClick={() => setModalLogout(false)}>
                                        Отмена
                                    </Button>
                                </Stack>
                            </ModalDialog>
                        </ModalOverflow>
                    </Modal>
                )
            }
        </AnimatePresence>
    );
}