import { Box, Button, Card, CardContent, Checkbox, DialogContent, DialogTitle, Divider, FormControl, FormLabel, IconButton, Input, Modal, ModalClose, ModalDialog, ModalOverflow, Radio, Sheet, Stack, Typography } from "@mui/joy";
import { useContext, useState } from "react";
import { LayoutContext } from "../../context";
import { motion, AnimatePresence } from "framer-motion";
import InputMask from 'react-input-mask';
import { useLocation, useNavigate } from "react-router-dom";
import TwoWheelerRoundedIcon from '@mui/icons-material/TwoWheelerRounded';




export default function ModalInfo() {
    const navigate = useNavigate();
    const { modalInfo, setModalInfo } = useContext(LayoutContext);

    return (
        <AnimatePresence>
            {
                modalInfo.open && (
                    <Modal
                        open={modalInfo.open}
                        onClose={() => setModalInfo({ ...modalInfo, open: false })}
                        slotProps={{
                            backdrop: {
                                component: motion.div,
                                initial: { opacity: 0, backdropFilter: 'blur(0px)' },
                                animate: { opacity: 1, backdropFilter: 'blur(8px)' },
                                exit: { opacity: 0, backdropFilter: 'blur(0px)' },
                                transition: { duration: 0.3, ease: "easeInOut" },
                            }
                        }}
                    >
                        <ModalOverflow sx={{ height: 'auto' }}>
                            <ModalDialog
                                size="lg"
                                component={motion.div}
                                initial={{ opacity: 0, y: -10, scale: 0.98 }}
                                animate={{ opacity: 1, y: 0, scale: 1 }}
                                exit={{ opacity: 0, y: 10, scale: 0.98 }}
                                transition={{ duration: 0.2, ease: "easeInOut" }}
                                sx={{
                                    maxWidth: 600,
                                    width: {
                                        xs: '100%',
                                        sm: "auto"
                                    }
                                }}
                            >
                                <ModalClose />
                                <Typography sx={{ textAlign: 'center', mb: 3 }} level="h4">
                                    Блоггеры и посты
                                </Typography>
                                <img src="/screen1.png" width={'100%'} />
                                <Typography sx={{ mb: 3 }} level="body-md">
                                    Блоггеры и посты и раздел позволяющий что то там делать чтобы ты что то делал и что то продвигал, ну или что то смотрел
                                </Typography>
                                <img src="/screen2.png" width={'100%'} />
                                <Typography sx={{ mb: 3 }} level="body-md">
                                    Можно там фильтровать эти посты и блоггеров
                                </Typography>
                            </ModalDialog>
                        </ModalOverflow>
                    </Modal>
                )
            }
        </AnimatePresence>
    );
}