import { Box, Button, Card, CardContent, Checkbox, DialogContent, DialogTitle, Divider, FormControl, FormLabel, IconButton, Input, Modal, ModalClose, ModalDialog, ModalOverflow, Radio, Sheet, Stack, Typography } from "@mui/joy";
import { useContext, useState } from "react";
import { LayoutContext } from "../../context";
import { motion, AnimatePresence } from "framer-motion";
import InputMask from 'react-input-mask';
import { useLocation, useNavigate } from "react-router-dom";
import TwoWheelerRoundedIcon from '@mui/icons-material/TwoWheelerRounded';


import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import MonochromePhotosRoundedIcon from '@mui/icons-material/MonochromePhotosRounded';
import PageviewRoundedIcon from '@mui/icons-material/PageviewRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';


export default function ModalRate() {
    const navigate = useNavigate();
    const { modalRate, setModalRate } = useContext(LayoutContext);

    const [ selectRates, setSelectRates ] = useState([]);
    const [ selectDay, setSelectDay ] = useState(1);


    const rates = [
        {
            id: 1,
            name: 'Unit-экономика',
            price: 350,
            icon: <BarChartRoundedIcon sx={{ fontSize: 'xl2' }} />,
        },
        {
            id: 2,
            name: 'Поиск по артикулу',
            price: 600,
            icon: <MonochromePhotosRoundedIcon sx={{ fontSize: 'xl2' }} />,
        },
        {
            id: 3,
            name: 'База блогеров',
            price: 400,
            icon: <PageviewRoundedIcon sx={{ fontSize: 'xl2' }} />,
        }
    ]

    const days = [
        {
            id: 1,
            sp: 1,
            name: 'На 2 дня',
        },
        {
            id: 2,
            sp: 2,
            name: 'На 7 дней',
        },
        {
            id: 3,
            sp: 5,
            name: 'На 30 дней',
        }
    ]


    const [ phone, setPhone ] = useState();

    return (
        <AnimatePresence>
            {
                modalRate && (
                    <Modal
                        open={modalRate}
                        onClose={() => setModalRate(false)}
                        slotProps={{
                            backdrop: {
                                component: motion.div,
                                initial: { opacity: 0, backdropFilter: 'blur(0px)' },
                                animate: { opacity: 1, backdropFilter: 'blur(8px)' },
                                exit: { opacity: 0, backdropFilter: 'blur(0px)' },
                                transition: { duration: 0.3, ease: "easeInOut" },
                            }
                        }}
                    >
                        <ModalOverflow sx={{ height: 'auto' }}>
                            <ModalDialog
                                size="lg"
                                component={motion.div}
                                initial={{ opacity: 0, y: -10, scale: 0.98 }}
                                animate={{ opacity: 1, y: 0, scale: 1 }}
                                exit={{ opacity: 0, y: 10, scale: 0.98 }}
                                transition={{ duration: 0.2, ease: "easeInOut" }}
                                sx={{
                                    width: {
                                        xs: '100%',
                                        sm: "auto"
                                    }
                                }}
                            >
                                <ModalClose />
                                <Typography sx={{ textAlign: 'center', mb: 3 }} level="h2">
                                    Конструктор тарифа
                                </Typography>
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        setModalRate(false);
                                        navigate("/lk/unit");
                                    }}
                                >
                                    <Stack spacing={2} direction={"row"}>
                                        <Stack spacing={2} direction={"row"}>
                                            <Sheet
                                                variant="soft"
                                                sx={{
                                                    borderRadius: 'xl',
                                                    p: 3,
                                                    // width: 200
                                                }}
                                            >
                                                <Typography level="title-lg" sx={{ mb: 2, textAlign: 'center' }}>Инструменты</Typography>
                                                <Box
                                                    sx={{
                                                        display: 'grid',
                                                        gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
                                                        gap: 1.5,
                                                    }}
                                                >
                                                    { rates.map((item) => {
                                                        const dayObj = selectDay ? days.find(obj => obj.id === selectDay) : undefined;
                                                        const selected = selectRates.includes(item.id);
                                                        return (
                                                            <Card
                                                                color={selected ? "primary" : "neutral"}
                                                                variant="plain"
                                                                key={item.name}
                                                                sx={{
                                                                    gap: 0,
                                                                    // boxShadow: 'none',
                                                                    transition: ".2s",
                                                                    '&:hover': { 
                                                                        bgcolor: 'primary.50',
                                                                        transform: 'scale(1.05)'
                                                                    },
                                                                }}
                                                            >
                                                                <CardContent>
                                                                    {item.icon}
                                                                    <Typography level="title-md">{item.name}</Typography>
                                                                    <Typography level="body-sm">{item.price * dayObj.sp} ₽</Typography>
                                                                </CardContent>
                                                                <Checkbox
                                                                    size="sm"
                                                                    color="neutral"
                                                                    overlay
                                                                    disableIcon
                                                                    variant="outlined"
                                                                    checked={selected}
                                                                    onChange={(event) =>
                                                                        setSelectRates((prev) => {
                                                                            const set = new Set([...prev, item.id]);
                                                                            if (!event.target.checked) {
                                                                                set.delete(item.id);
                                                                            }
                                                                            return [...set];
                                                                        })
                                                                    }
                                                                    slotProps={{
                                                                        action: {
                                                                            sx: {
                                                                            ...(selected && {
                                                                                borderWidth: 2,
                                                                                borderColor:
                                                                                'var(--joy-palette-primary-outlinedBorder)',
                                                                            }),
                                                                            '&:hover': {
                                                                                bgcolor: 'transparent',
                                                                            },
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            </Card>
                                                        );
                                                    })}
                                                </Box>
                                            </Sheet>
                                            <Sheet
                                                variant="soft"
                                                sx={{
                                                    borderRadius: 'xl',
                                                    p: 3,
                                                    // width: 200
                                                }}
                                            >
                                                <Typography level="title-lg" sx={{ mb: 2, textAlign: 'center' }}>Срок</Typography>
                                                <Box
                                                    sx={{
                                                        display: 'grid',
                                                        gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
                                                        gap: 1.5,
                                                    }}
                                                >
                                                    {
                                                    days.map((item) => {
                                                        return (
                                                            <Card
                                                                color="primary"
                                                                variant="plain"
                                                                key={item.name}
                                                                sx={{
                                                                    gap: 0,
                                                                    // boxShadow: 'none',
                                                                    transition: ".2s",
                                                                    '&:hover': { 
                                                                        bgcolor: 'primary.50',
                                                                        transform: 'scale(1.05)'
                                                                    },
                                                                }}
                                                            >
                                                                <CardContent>
                                                                    <Typography level="title-md">{item.name}</Typography>
                                                                </CardContent>
                                                                <Radio
                                                                    size="sm"
                                                                    color="neutral"
                                                                    overlay
                                                                    disableIcon
                                                                    variant="outlined"
                                                                    checked={item.id == selectDay}
                                                                    onChange={() => setSelectDay(item.id)}
                                                                    slotProps={{
                                                                    action: {
                                                                        sx: {
                                                                        ...(item.id == selectDay && {
                                                                            borderWidth: 2,
                                                                            borderColor:
                                                                            'var(--joy-palette-primary-outlinedBorder)',
                                                                        }),
                                                                        '&:hover': {
                                                                            bgcolor: 'transparent',
                                                                        },
                                                                        },
                                                                    },
                                                                    }}
                                                                />
                                                            </Card>
                                                        );
                                                    })}
                                                </Box>
                                            </Sheet>
                                        </Stack>
                                        { selectRates.length > 0 &&
                                            (
                                                <Sheet
                                                    variant="outlined"
                                                    sx={{
                                                        height: "fit-content",
                                                        borderRadius: 'xl',
                                                        p: 3,
                                                        boxShadow: 'lg',
                                                        maxWidth: 300
                                                    }}
                                                >
                                                    <Stack spacing={2}>
                                                        <Stack spacing={2}>
                                                            <Typography level="h4" color="primary">
                                                                Ваш заказ
                                                            </Typography>
                                                            {
                                                                selectRates.map((targetId) => {
                                                                    const obj = rates.find(obj => obj.id === targetId);
                                                                    const dayObj = selectDay ? days.find(obj => obj.id === selectDay) : undefined;
                                                                    return (
                                                                        <Stack spacing={2} direction={"row"} sx={{ alignItems: "center", justifyContent: "space-between" }}>
                                                                            <Box>
                                                                                <Typography level="title-sm">
                                                                                    {obj?.name}
                                                                                </Typography>
                                                                                <Typography level="body-sm">
                                                                                    Доступ к функции "{obj?.name}" {dayObj?.name}
                                                                                </Typography>
                                                                                <Typography level="body-sm" color="primary" sx={{ fontWeight: 'xl' }}>
                                                                                    {obj?.price * dayObj.sp} ₽
                                                                                </Typography>
                                                                            </Box>
                                                                            <IconButton size="sm"
                                                                                onClick={(event) =>
                                                                                    setSelectRates((prev) => {
                                                                                        const set = new Set([...prev, obj?.id]);
                                                                                        set.delete(obj?.id);
                                                                                        return [...set];
                                                                                    }
                                                                                )}
                                                                            >
                                                                                <ClearRoundedIcon/>
                                                                            </IconButton>
                                                                        </Stack>
                                                                    )
                                                                })
                                                            }
                                                            <Divider/>
                                                            <Stack spacing={2} direction={"row"} sx={{ justifyContent: "space-between" }}>
                                                                <Typography level="h4">
                                                                    {
                                                                        selectRates.reduce((partialSum, targetId) => {
                                                                            const obj = rates.find(obj => obj.id === targetId);
                                                                            const dayObj = days.find(obj => obj.id === selectDay);
                                                                            return partialSum + (obj?.price * dayObj.sp)
                                                                        }, 0)
                                                                    } ₽
                                                                </Typography>
                                                                <Typography level="h4">
                                                                    Итого
                                                                </Typography>
                                                            </Stack>
                                                            <Divider/>
                                                        </Stack>
                                                        <Stack spacing={1.5}>
                                                            <FormControl>
                                                                <FormLabel>Имя*</FormLabel>
                                                                <Input size="sm" autoFocus required name="name" placeholder="Иван" />
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel>Номер телефона*</FormLabel>
                                                                <InputMask mask="+7 (999) 999-99-99" value={phone} onChange={(e) => setPhone(e.target.value)}>
                                                                    {(inputProps) => <Input {...inputProps} type="tel" size="sm" required name="phone" disableUnderline placeholder="+7 (XXX) XXX-XX-XX" />}
                                                                </InputMask>
                                                            </FormControl>
                                                            <Button variant="solid" color="success" type="submit">
                                                                Оплатить {
                                                                    selectRates.reduce((partialSum, targetId) => {
                                                                        const obj = rates.find(obj => obj.id === targetId);
                                                                        const dayObj = days.find(obj => obj.id === selectDay);
                                                                        return partialSum + (obj?.price * dayObj.sp)
                                                                    }, 0)
                                                                } ₽
                                                            </Button>
                                                        </Stack>
                                                    </Stack>
                                                </Sheet>
                                            )
                                        }
                                    </Stack>
                                </form>
                            </ModalDialog>
                        </ModalOverflow>
                    </Modal>
                )
            }
        </AnimatePresence>
    );
}