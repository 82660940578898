import { Box, Button, FormControl, FormLabel, Input, Stack } from "@mui/joy";

import BlockStart from "../blocks/StartPage/BlockStart";
import { useContext, useState } from "react";
import { LayoutContext } from "../context";

export default function PageLock() {
    const { lock, setLock } = useContext(LayoutContext);

    const [ login, setLogin ] = useState();
    const [ pwd, setPwd ] = useState();

    function entryLk() {
        const _login = 'admin';
        const _pwd = '15346';

        if (login == _login && pwd == _pwd) {
            setLock(false);
        }
        else alert("Вход не подтвержден");
    }

    return (
        <Box 
            sx={{
                zIndex: 10000,
                height: '100vh',
                width: '100vw',
                overflowY: 'scroll',
                position: 'absolute',
                bgcolor: 'white',
                top: 0,
                scrollSnapType: 'y mandatory',
                '& > div': {
                    scrollSnapAlign: 'start',
                },
            }}
        >
            <Stack spacing={2} sx={{ width: '200px', height: '100%', m: 'auto', justifyContent: "center" }}>
                <FormControl>
                    <FormLabel>Логин*</FormLabel>
                    <Input size="sm" value={login} onChange={(e) => setLogin(e.target.value)} type="text" autoFocus required name="login" placeholder="Иван" />
                </FormControl>
                <FormControl>
                    <FormLabel>Пароль*</FormLabel>
                    <Input size="sm" value={pwd} onChange={(e) => setPwd(e.target.value)} type="password" required name="pwd" placeholder="***" />
                </FormControl>
                <Button variant="solid" color="success" onClick={entryLk}>
                    Вход
                </Button>
            </Stack>
        </Box>
    );
}