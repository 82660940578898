import { Box, Button, Container, IconButton, Link, Sheet, Stack, Typography } from "@mui/joy";

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';
import { LayoutContext } from "../../context";
import { useContext } from "react";


export default function Header() {
    const navigate = useNavigate();
    const { account, setModalReg, setModalAuth } = useContext(LayoutContext);

    const checkObj = (obj) => Object.keys(obj).length === 0

    return (
        <Sheet
            component={'header'}
            color="neutral"
            variant="soft"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: {
                    xs: "column",
                    sm: "row"
                },
                top: 0,
                // height: 'var(--Header-height)',
                // zIndex: 9995,
                py: 1,
                px: 2,
                gap: 1,
                borderRadius: 'md',
                borderColor: 'none',
            }}
        >
            <Stack direction={"row"} spacing={0.5} sx={{ alignItems: 'center' }}>
                <img src="/logo.png" height={55}/>
                <Typography level="h3">
                    Trendlab.pro
                </Typography>
            </Stack>
            <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={4}
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Link href="#price" level="body-sm">
                        Цены
                    </Link>
                    <Link href="#advantages" level="body-sm">
                        Преимущества
                    </Link>
                    <Link href="#services" level="body-sm">
                        Сервисы
                    </Link>
                </Stack>
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Button onClick={() => setModalReg(true)} size="md" variant="solid">
                        Регистрация
                    </Button>
                    <Button onClick={() => checkObj(account) ? setModalAuth(true) : navigate("/lk/unit")} variant="solid" color="neutral" size="md" endDecorator={<ExitToAppRoundedIcon fontSize="sm" />}>
                        Войти
                    </Button>
                </Stack>
            </Stack>
        </Sheet>
    );
}